var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "회사 목록",
                  tableId: "company",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "회사 정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "등록", icon: "add" },
                                  on: { btnClicked: _vm.addData },
                                })
                              : _vm._e(),
                            _vm.saveEditable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.company,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              label: "회사명",
                              name: "companyName",
                            },
                            model: {
                              value: _vm.company.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "companyName", $$v)
                              },
                              expression: "company.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              bizNumberCheck: true,
                              label: "사업자번호",
                              name: "bizNo",
                            },
                            model: {
                              value: _vm.company.bizNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "bizNo", $$v)
                              },
                              expression: "company.bizNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              label: "대표자",
                              name: "managerName",
                            },
                            model: {
                              value: _vm.company.managerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "managerName", $$v)
                              },
                              expression: "company.managerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              label: "대표 E-mail",
                              name: "managerEmail",
                            },
                            model: {
                              value: _vm.company.managerEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "managerEmail", $$v)
                              },
                              expression: "company.managerEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              phoneNumberCheck: true,
                              label: "전화번호",
                              name: "phoneNo",
                            },
                            model: {
                              value: _vm.company.phoneNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "phoneNo", $$v)
                              },
                              expression: "company.phoneNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              isFlag: true,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.company.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "useFlag", $$v)
                              },
                              expression: "company.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              label: "비고",
                              name: "remarks",
                            },
                            model: {
                              value: _vm.company.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "remarks", $$v)
                              },
                              expression: "company.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }