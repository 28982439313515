<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="회사 목록"
          tableId="company"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
                  <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="회사 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="saveEditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="company"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :required="true"
                  :disabled="!saveMode"
                  :editable="editable"
                  label="회사명"
                  name="companyName"
                  v-model="company.companyName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :disabled="!saveMode"
                  :editable="editable"
                  :bizNumberCheck="true"
                  label="사업자번호"
                  name="bizNo"
                  v-model="company.bizNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="대표자"
                  name="managerName"
                  v-model="company.managerName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="대표 E-mail"
                  name="managerEmail"
                  v-model="company.managerEmail">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  :phoneNumberCheck="true"
                  label="전화번호"
                  name="phoneNo"
                  v-model="company.phoneNo">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 사용여부 -->
                <c-checkbox
                  :disabled="!saveMode"
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="company.useFlag"
                />
              </div>
              <div class="col-12">
                <!-- 비고 -->
                <c-textarea
                  :disabled="!saveMode"
                  :editable="editable"
                  label="비고"
                  name="remarks"
                  v-model="company.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'company-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'companyName',
            field: 'companyName',
            // 회사명
            label: '회사명',
            align: 'left',
            style: 'width:70%',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: '사용여부',
            align: 'center',
            style: 'width:30%',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        useFlag: 'Y',
      },
      company: {
        mdmCompanyId: '',  // 회사 일련번호
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '', // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      useFlagItems: [],
      editable: true,
      saveMode: false,
      isSave: false,
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    saveEditable() {
      return this.editable && this.saveMode
    }
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.company.list.url;
      this.detailUrl = selectConfig.mdm.company.get.url;
      this.saveUrl = transactionConfig.mdm.company.insert.url;
      // code setting
      // list scope
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      this.$http.url = this.$format(this.detailUrl, row.mdmCompanyId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.company, _result.data);
        this.saveMode = true;
      },);
    },
    addData() {
      Object.assign(this.$data.company, this.$options.data().company);
      this.saveMode = true;
    },
    saveData() {
      if (this.company.mdmCompanyId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.mdm.company.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.mdm.company.insert.url;
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.company.regUserId = this.$store.getters.user.userId
              this.company.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.company.mdmCompanyId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ mdmCompanyId: result.data });
    },
  }
};
</script>
